import React, {Fragment} from 'react';
import HtmlParser from 'react-html-parser';
import ProductKeyPoints from './ProductKeyPoints';
import ProductPoints from './ProductPoints';
import ProductIngredients from './ProductIngredients';
import ProductSidebar from './ProductSidebar'
import Slider from "react-slick";
import '../../Content/css/Slider.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'gatsby';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class SingleProductTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      productContent: this.props.productContent,
      productMedia: this.props.productMedia,
      size: 'small',
      showDots: true,
      slideCount: 2,
      slidesToScroll: 2
    }
  }
  componentDidMount() {
    this.setState({productContent: this.props.productContent, productMedia: this.props.productMedia})
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    if (window.innerWidth <= 767) {
      this.setState({size: 'small', showDots: true, slideCount: 2, slidesToScroll: 2})
    }
    if (window.innerWidth > 768) {
      this.setState({size: 'large', showDots: true, slideCount: 4, slidesToScroll: 4})
    }
  }
  handleNavigation() {
    this.props.handleProductNavigation()

  }

  render() {
    let slides = this
      .props
      .relatedProducts
      .map((item, key) => <div className="relatedProductSlide" key={key}>
        <Link
          to={item.link.link}
          onClick={this
          .handleNavigation
          .bind(this)}>
          <img src={gravURL + item.media.productFeatureImage} alt={item.title}/>
          <h2>{item.title}</h2>
          <p className="text-xs">{HtmlParser(item.shortDescription)}</p>
        </Link>
      </div>)

    let parsedProductShortDescription = HtmlParser(this.state.productContent.short_product_description);
    let parsedProductExtraInfo = HtmlParser(this.state.productContent.extra_info);
    let parsedDisclaimer = HtmlParser(this.state.productContent.disclaimer);
    let parsedDosage = HtmlParser(this.state.productContent.dosage);
    const {dosage} = this.state.productContent;
    return (
      <div className="sm:block lg:flex flex-wrap my-6 pb-10">
        <div className="lg:w-7/12 px-4 lg:px-0">
          <img
            src={this.props.gravUrl + this.state.productMedia.productFeatureImage}
            className="productPageFeatureImg"
            alt={this.state.productContent.title}
            width="280"
            height="418"/>
          <div className="productPageTitleWrapper">
            <h1 classNme="text-2xl">{this.state.productContent.title}</h1>
            <h3>{parsedProductShortDescription}</h3>
          </div>
          <div className="productKeyPointsWrapper">
            <ProductKeyPoints keyPoints={this.state.productContent.key_points}/>
          </div>
          {this.state.productContent.bold_points && <div className="productPoints">
            <ProductPoints points={this.state.productContent.bold_points}/>
          </div>
}
          <p>{parsedProductExtraInfo}</p>
          <ProductIngredients
            components={this.state.productContent.main_ingredient_components}
            ingredients={this.state.productContent.ingredients}/> {dosage && <Fragment>
            <div>
              <h4 className="productSubHeading">Directions:</h4>
              <div>{parsedDosage}</div>
            </div>
            <p className="disclaimer">
              <strong>{this.state.productContent.bold_disclaimer}</strong><br/>
              <small>{parsedDisclaimer}</small>
            </p>
          </Fragment>
}
        </div>
        <div className="lg:w-2/12"></div>
        <div className="lg:w-3/12">
          <ProductSidebar/>
        </div>
        <div className="clearFix w-full">
          <div className="w-full my-10">
            {slides && <Slider
              dots={this.state.showDots}
              infinite={true}
              speed={900}
              variableWidth={false}
              useTransform={false}
              arrows={true}
              autoplaySpeed={8000}
              autoplay={true}
              fade={false}
              slidesToShow={this.state.slideCount}
              slidesToScroll={this.state.slidesToScroll}
              pauseOnHover={true}>
              {slides}
            </Slider>
}
          </div>
        </div>
      </div>
    );
  }
}