import React from 'react';

const ProductPoints = (props) => {
	let points = props.points.map((item, key) =>
		<p key={key}>{item.statement}</p>
	);
	return (
		<div>
			{points}
		</div>
	);
}
export default ProductPoints;