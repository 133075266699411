import React from 'react';
import IngredientComponents from './IngredientComponents';
import IngredientList from './IngredientList';
const ProductIngredients = (props) => {
	const {components, ingredients} = props;
	return (
		<div className="ingredientsWrapper">
			{components || ingredients? 
				<h4 className="productSubHeading">Ingredients:</h4>
				:null
			}
			{components?
				<IngredientComponents components={props.components}/>
				:null
			}
			{ingredients?
				<IngredientList ingredients={props.ingredients}/>
				:null
			}
		</div>
	);
}
export default ProductIngredients;