import React from 'react';
const IngredientList = (props) => {
	const ingredients = props.ingredients.map((item, key) =>
		<li><strong>{item.ingredientKey} </strong>{item.ingredient}</li>
	);
	return (
		<ul className="ingredientList">
			{ingredients}
		</ul>
	);
}
export default IngredientList;
