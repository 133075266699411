import React from 'react';
import '../../Content/css/products.css';
import SingleProduct from './SingleProduct';
import Helmet from 'react-helmet'
let siteURL = process.env.REACT_APP_APP_URL;

export default function ProductsPage(props) {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc} = props.pageContext.head;
		return (
			<main>
				<Helmet>
					<title>Products | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={shareImg} />
					<meta property="og:image:url" content={shareImg}/>
					<meta name="twitter:image" content={shareImg}/>
					<meta name="twitter:card" content={shareImg}/>
				</Helmet>
				<SingleProduct url={null} content={props.pageContext} allProducts={props.pageContext.products}/>
			</main>
		);
}
