import React from 'react';
import SingleProductTemplate from './SingleProductTemplate';
import ProductProgrammeTemplate from './ProductProgrammeTemplate';
let gravURL = process.env.REACT_APP_GRAV_URL;

export default class SingleProduct extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			gravURL: gravURL,
			productContent: this.props.content.header,
			productMedia: this.props.content.media,
			loading: true,
			relatedProducts: [],
			headContent: this.props.content.head
		}
	}
	componentDidMount(){
		window.scrollTo(0,0);
		let branding = this.props.content.header.productBrand;
		let sd = this.props.allProducts.pagesSD;
		let ud = this.props.allProducts.pagesUD;
		let relatedProducts = [];
		if(branding === 'sd2' || branding === 'both'){
			for(let i = 0; sd.length > i;i++ ){
				let product = {
					title: sd[i].header.title,
					link: sd[i].link,
					media: sd[i].media,
					shortDescription: sd[i].header.short_product_description
				}
				relatedProducts.push(product);
			}
			this.setState({relatedProducts: relatedProducts})
		}
		if(branding === 'ud2'){
			for(let i = 0; ud.length > i;i++ ){
				let product = {
					title: ud[i].header.title,
					link: ud[i].link,
					media: ud[i].media,
					shortDescription: ud[i].header.short_product_description
				}
				relatedProducts.push(product);
			}
			this.setState({relatedProducts: relatedProducts})
		}
		this.setState({loading: false})
	}
	handleOtherProductNavigation(){
		window.scrollTo(0,0);
	}
	componentWillUnmount(){
		this._isMounted = false;
	}
	render() {
		const singleProduct = this.props.content.header.singleProduct === true;
		return (
			<div className="py-6">
				<div className="pageGrid productPageGrid">
					{singleProduct?
						<SingleProductTemplate productContent={this.state.productContent}
							productMedia={this.state.productMedia}
							gravUrl={gravURL}
							relatedProducts={this.state.relatedProducts}
							handleProductNavigation={this.handleOtherProductNavigation.bind(this)}
						/>
					:
						<ProductProgrammeTemplate productContent={this.state.productContent}
							productMedia={this.state.productMedia}
							gravUrl={gravURL}
							relatedProducts={this.state.relatedProducts}
							handleProductNavigation={this.handleOtherProductNavigation.bind(this)}
						/>
					}
				</div>
			</div>
		);
	}
}
