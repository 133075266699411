import React from 'react';
const IngredientComponents = (props) => {
	let components = props.components.map((item, key)=>
		<span className="ingredientComponent" key={key}>{item.component}</span>
	);
	return (
		<div className="ingredientComponents">
			{components}
		</div>
	);
}
export default IngredientComponents;