import React from 'react';

const ProductKeyPoints = (props) => {
  let keyPoints = props
    .keyPoints
    .map((item, key) => <li key={key}>
      <span>{item.keypoint}</span>
    </li>);
  return (
    <div>
      <ul className="keyPointsList">
        {keyPoints}
      </ul>
    </div>
  );
}
export default ProductKeyPoints;