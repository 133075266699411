import React from 'react';
import HtmlParser from 'react-html-parser';
import ProductKeyPoints from './ProductKeyPoints';
import ProductPoints from './ProductPoints';
import ProductIngredients from './ProductIngredients';
import ProductSidebar from './ProductSidebar'
import '../../Content/css/Slider.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'gatsby';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class SingleProductTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productContent: this.props.productContent,
      productMedia: this.props.productMedia,
      relatedProducts: this.props.relatedProducts
    };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    if (window.innerWidth <= 767) {
      this.setState({size: 'small', showDots: true, slideCount: 2, slidesToScroll: 2})
    }
    if (window.innerWidth > 768) {
      this.setState({size: 'large', showDots: true, slideCount: 4, slidesToScroll: 4})
    }
  }
  handleNavigation() {
    this.props.handleProductNavigation()

  }
  render() {
    let parsedProductExtraInfo = HtmlParser(this.state.productContent.extra_info);
    let slides = this
      .props
      .relatedProducts
      .map((item, key) => <div className="relatedProductSlide">
        <Link
          to={item.link.link}
          onClick={this
          .handleNavigation
          .bind(this)}>
          <img src={gravURL + item.media.productFeatureImage} alt={item.title}/>
          <h2>{item.title}</h2>
          <p>{HtmlParser(item.shortDescription)}</p>
        </Link>
      </div>)
    return (
      <div className="lg:flex flex-wrap md:10">
        <div className="w-full lg:w-7/12">
          <img
            src={this.props.gravUrl + this.state.productMedia.productFeatureImage}
            className="programmeFeatureImg"
            alt={this.state.productContent.title}/>
          <div className="productPageTitleWrapper">
            <h1>{this.state.productContent.title}</h1>

          </div>
          <div className="productKeyPointsWrapper">
            <ProductKeyPoints keyPoints={this.state.productContent.key_points}/>
          </div>
          <div className="productPoints">
            <ProductPoints points={this.state.productContent.bold_points}/>
          </div>
          <p>
            {parsedProductExtraInfo}
          </p>
          <ProductIngredients
            components={this.state.productContent.main_ingredient_components}
            ingredients={this.state.productContent.ingredients}/>

        </div>
        <div className="w-full lg:w-2/12"></div>
        <div className="w-full lg:w-3/12">
          <ProductSidebar/>
        </div>
        <div className="w-full mt-5 mb-10">
          <Slider
            dots={this.state.showDots}
            infinite={true}
            speed={900}
            variableWidth={false}
            useTransform={false}
            arrows={true}
            autoplaySpeed={8000}
            autoplay={true}
            fade={false}
            slidesToShow={this.state.slideCount}
            slidesToScroll={this.state.slidesToScroll}
            pauseOnHover={true}>
            {slides}
          </Slider>
        </div>

      </div>
    );
  }
}
